import React, { useCallback } from 'react';

import styled from '@emotion/styled';


import { css } from '@emotion/react';
import { List } from 'antd';
import Title from 'antd/es/typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../config/colors';
import { clearSelections, setIsRegionOpen, setSelectedGallery } from '../../../features/regionsSlice';
import TextInfoBG from '../../../images/info_box_bio.png';
import CloseX from '../../../images/x_close.png';


const Wrapper = styled.div`
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    z-index: 99999;

    ${props => props.open && css`
        pointer-events: all;
        opacity: 1;
    `}

    
`;

const BlackBase = styled.div`
    background-color: rgba(0,0,0,0.7);
    width: 100vw;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
`;

const CenterContent = styled.div`
    /* width: 100vw;
    height: 100%; */
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainContentSectionWrapper = styled.div`
    width: 679px;
    height: 434px;
    background-image: url(${TextInfoBG});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
   
`;

const BioContentWrapper = styled.div`
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    padding: 30px;
    position: relative;
`;

const TextAreaWrapper = styled.div`
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
`

const TextAreaCustom = styled.p`//styled(TextArea)
    white-space: pre-wrap;
    font-family: IBMPlexSansHebrew-Regular;
    line-height: 25px;
    background: transparent;
    font-size: 1.2rem;
    padding-top: 15px;
    text-align: center;
`

const RectangleBullet = styled.div`
    border: 1px solid ${COLORS.colorBrown};
    width: 17px;
    height: 17px;
    margin: 0;
    padding: 0;
`;


export default function LinksToBuildingModal({ open, handleClose, linksBtns, extraCleanup }) {
    const dispatch = useDispatch();

    const { language } = useSelector(
        (state) => state.language
    );

    const isHebrew = () => language === "he";

    const closeRegion = () => {
        dispatch(setIsRegionOpen(false));
    };

    const clearGallery = useCallback(() => {
        dispatch(setSelectedGallery(null));
    }, [dispatch])

    const clearAllSelections = useCallback(() => {
        dispatch(clearSelections());
    }, [dispatch])


    const openLink = (btnId) => () => {
        const foundLink = linksBtns.find((btn) => btn.id === btnId);
        if (foundLink) {
            localStorage.setItem("link", foundLink.link);
            if (extraCleanup) {
                // extraCleanup();
            }
            clearAllSelections();
            // clearGallery();
            // closeRegion();

            handleClose();
        }

    }


    return (
        <Wrapper
            open={open}
            direction={isHebrew() ? "rtl" : "ltr"}
        >

            <BlackBase onClick={handleClose} />

            <CenterContent>
                <img
                    src={CloseX} alt="close-biography"
                    onClick={handleClose}
                    style={{
                        cursor: "pointer",
                        position: 'absolute',
                        top: 25,
                        left: 30,
                        zIndex: 1000
                    }}
                />
                <MainContentSectionWrapper >
                    <BioContentWrapper>

                        <Title
                            level={3}
                            style={
                                {
                                    margin: 0,
                                    padding: 0,
                                    zIndex: 999,
                                    fontFamily: "IBMPlexSansHebrew-Bold"
                                }
                            }
                        >
                            {isHebrew() ? "אתרים קשורים" : "related locations"}
                        </Title>


                        <TextAreaWrapper>
                            <List
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "0 25px"
                                }}
                                dataSource={linksBtns}
                                renderItem={(item) => (
                                    <List.Item
                                        style={{
                                            alignSelf: "flex-start",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "10px",
                                            fontSize: "1.5rem",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            fontFamily: "IBMPlexSansHebrew-Regular"
                                        }}
                                        onClick={openLink(item.id)}
                                    >
                                        <RectangleBullet />
                                        {item.title}
                                    </List.Item>
                                )}
                            />
                        </TextAreaWrapper>
                    </BioContentWrapper>
                </MainContentSectionWrapper>
            </CenterContent>
        </Wrapper>
    )
}
