/* eslint-disable react-hooks/exhaustive-deps */
import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { AutoComplete, Button, Divider, Input } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';


const FileInfoWrapper = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start;

`;

const FileInfoWrapperBottom = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

const REGION_GALLERIES_ENG = ["photos", "testimonials", "objects", "art", "documents"];
const REGION_GALLERIES_HEB = ["תמונות", "עדויות", "חפצים", "אומנות", "מסמכים"];
const REGION_GALLERIES_ENG_CONVERT = {
    "תמונות": "photos",
    "עדויות": "testimonials",
    "חפצים": "objects",
    "אומנות": "art",
    "מסמכים": "documents"
};
const REGION_GALLERIES_HEB_CONVERT = {
    "photos": "תמונות",
    "testimonials": "עדויות",
    "objects": "חפצים",
    "art": "אומנות",
    "documents": "מסמכים"
};
export default function LinksItemInfoPopover({ REGIONS, linkItem, createItem, updateItem, closeMe }) {

    // const { items: REGIONS } = useSelector(state => state.regions);

    const [lang, setLang] = useState("he");

    const [itemTitle, setItemTitle] = useState(linkItem?.title || "");
    const [itemTitleEng, setItemTitleEng] = useState(linkItem?.titleEng || "");

    const [selectedRegion, setSelectedRegion] = useState();
    const [selectedRegionGallery, setSelectedRegionGallery] = useState();
    const [selectedGalleryItem, setSelectedGalleryItem] = useState();
    const [regionSearch, setRegionSearch] = useState();
    const [regionGallerySearch, setRegionGallerySearch] = useState();
    const [galleryItemSearch, setGalleryItemSearch] = useState();


    const [galleryItems, setGalleryItems] = useState([]);


    const closePopover = () => {
        clearInputs();
        closeMe();
    }

    const handleSearchRegionSelect = (value) => {
        const regionToSelect = REGIONS.find((region) => region.id === Number(value));
        if (regionToSelect) {
            setSelectedRegion({ value: regionToSelect.id.toString(), label: isEnglish() ? regionToSelect.nameEng : regionToSelect.name });
            setTimeout(() => {
                setRegionSearch("");
            }, 10);
        }
    }

    const getRegionName = (value) => {
        const regionToSelect = REGIONS.find((region) => region.id === Number(value));
        if (regionToSelect) {
            return isEnglish() ? regionToSelect.nameEng : regionToSelect.name;

        }
    }

    const handleRegionInputChange = (value) => {
        setRegionSearch(value);
    }

    const handleRegionGalleryInputChange = (value) => {
        setRegionGallerySearch(value);
    }

    const handleSearchRegionGallerySelect = (value) => {
        setSelectedRegionGallery({ value, label: value });
        setTimeout(() => {
            setRegionGallerySearch("");
        }, 10);

        setSelectedGalleryItem();
    }

    const handleGalleryItemInputChange = (value) => {
        setGalleryItemSearch(value);
    }

    const handleSearchGalleryItemSelect = (value) => {
        const galleryItem = galleryItems.find(item => item.value === value);
        setSelectedGalleryItem({ ...galleryItem });
        setTimeout(() => {
            setGalleryItemSearch("");
        }, 10);
    }


    const getRegionOptions = () => {
        return REGIONS.map((region) => {
            return {
                value: region.id.toString(),
                label: isEnglish() ? region.nameEng : region.name
            };
        })
    }

    const getRegionGalleries = () => {
        if (selectedRegion) {
            const galleries = isEnglish() ? REGION_GALLERIES_ENG : REGION_GALLERIES_HEB;
            return galleries.map((gallery) => {
                return { value: gallery, label: gallery };
            })
        }
        return [];
    }

    const updateGalleryItems = () => {
        let items = [];
        if (selectedRegionGallery) {
            const galleryName = REGION_GALLERIES_ENG_CONVERT[selectedRegionGallery.value] || selectedRegionGallery.value;
            const regionFound = REGIONS.find((region) => region.id === Number(selectedRegion.value));
            items = regionFound[galleryName].map((file) => {
                return { value: file.id.toString(), label: file.title || file.fileName };
            });
        }
        setGalleryItems(items)
    }

    const createLinkInfo = async () => {
        const regionFound = REGIONS.find((region) => region.id === Number(selectedRegion.value));
        const galleryName = isEnglish() ? selectedRegionGallery.value : REGION_GALLERIES_ENG_CONVERT[selectedRegionGallery.value];
        const itemToCreate = {
            ...linkItem,
            title: itemTitle,
            titleEng: itemTitleEng,
            link: `${regionFound.id || -1}|${galleryName}`
        }
        if (createItem) {
            createItem(itemToCreate);
        }
    }

    const updateLinkInfo = async () => {
        const regionFound = REGIONS.find((region) => region.id === Number(selectedRegion.value));
        const galleryName = REGION_GALLERIES_ENG_CONVERT[selectedRegionGallery.value] || selectedRegionGallery.value;
        const galleryItemId = selectedGalleryItem ? selectedGalleryItem.value : "";

        let itemToUpdate = {
            ...linkItem,
            title: itemTitle,
            titleEng: itemTitleEng,
            link: `${regionFound.id || -1}|${galleryName}`
        }
        if (galleryItemId) {
            itemToUpdate = {
                ...itemToUpdate,
                link: `${itemToUpdate.link}|${galleryItemId}`
            }
        }
        if (updateItem) {
            try {
                await updateItem(itemToUpdate);
                closePopover();
            } catch (error) {

            }
        }

    }

    const toggleLanguage = () => {
        const langToChange = lang === "he" ? "en" : "he";
        setLang(langToChange);
    }

    const isHebrew = () => lang === "he";
    const isEnglish = () => lang === "en";

    const getDirection = () => {
        return lang === "he" ? "rtl" : "ltr";
    }
    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (lang === "he") {
            setItemTitle(value);
            return;
        }
        setItemTitleEng(value);
    }

    const isCreateDisabled = () => {
        return (!itemTitle && !itemTitleEng) || (!selectedRegion || !selectedRegionGallery);
    }

    const clearInputs = () => {
        setItemTitle("");
        setItemTitleEng("")
        setRegionSearch("");
        setRegionGallerySearch("");
        setSelectedRegion(undefined);
        setSelectedRegionGallery(undefined);
    }

    useEffect(() => {
        updateGalleryItems();
    }, [selectedRegion, selectedRegionGallery]);

    useEffect(() => {
        if (linkItem) {
            setItemTitle(linkItem.title);
            setItemTitleEng(linkItem.titleEng);

            const regionId = Number(linkItem.link.split("|")[0]);
            const regionGallery = linkItem.link.split("|")[1];
            const regionFound = REGIONS.find((region) => region.id === regionId);
            setSelectedRegion({
                value: regionFound.id.toString(),
                label: isEnglish() ? regionFound.nameEng : regionFound.name
            });
            setSelectedRegionGallery({
                value: regionGallery,
                label: regionGallery
            });
        }
    }, [linkItem]);

    useEffect(() => {
        if (!linkItem) {
            clearInputs();
        }
    }, [linkItem])


    return (
        <FileInfoWrapper dir={getDirection()}>
            <Button
                onClick={toggleLanguage}
                icon={<GlobalOutlined />}
                style={{ marginBottom: '15px' }}
            >
                {lang === "en" ? "he" : "en"}
            </Button>
            {isHebrew() && (
                <>
                    <Title level={5}>שם</Title>
                    <Input
                        placeholder="שם הקישור"
                        value={itemTitle}
                        onChange={handleTitleChange}
                    />
                </>
            )}
            {isEnglish() && (
                <>
                    <Title level={5}>Name</Title>
                    <Input placeholder="Link Name"
                        value={itemTitleEng}
                        onChange={handleTitleChange}
                    />
                </>)}

            <Divider />


            <AutoComplete
                allowClear
                style={{ width: "150px" }}
                dir={isEnglish() ? "ltr" : "rtl"}
                options={getRegionOptions()}
                onSelect={handleSearchRegionSelect}
                onSearch={handleRegionInputChange}
                value={regionSearch}
                filterOption={(inputValue, option) =>
                    isEnglish() ?
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
                        option.label.indexOf(inputValue) !== -1
                }
                placeholder={isEnglish() ? "Search a building" : "חיפוש מבנה"}

            />
            {isHebrew() && (
                <Title level={5}>בניין</Title>

            )}
            {isEnglish() &&
                <Title level={5}>Building</Title>

            }
            {selectedRegion && <Title style={{ margin: 0 }} level={4}>{getRegionName(selectedRegion.value)}</Title>}
            <Divider />
            <AutoComplete
                allowClear
                style={{ width: "150px" }}
                dir={isEnglish() ? "ltr" : "rtl"}
                options={getRegionGalleries()}
                onSelect={handleSearchRegionGallerySelect}
                onSearch={handleRegionGalleryInputChange}
                value={regionGallerySearch}
                filterOption={(inputValue, option) =>
                    isEnglish() ?
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
                        option.label.indexOf(inputValue) !== -1
                }
                placeholder={isEnglish() ? "Search a gallery" : "חיפוש גלריה"}

            />
            {isHebrew() && (
                <Title level={5}>גלריה</Title>

            )}
            {isEnglish() &&
                <Title level={5}>Gallery</Title>

            }
            {selectedRegionGallery && <Title style={{ margin: 0 }} level={4}>
                {!isEnglish() ? REGION_GALLERIES_HEB_CONVERT[selectedRegionGallery.value] : selectedRegionGallery.value}
            </Title>}
            <Divider />
            {galleryItems.length > 0 ?
                <>
                    <AutoComplete
                        allowClear
                        style={{ width: "150px" }}
                        dir={isEnglish() ? "ltr" : "rtl"}
                        options={galleryItems}
                        onSelect={handleSearchGalleryItemSelect}
                        onSearch={handleGalleryItemInputChange}
                        value={galleryItemSearch}
                        filterOption={(inputValue, option) =>
                            isEnglish() ?
                                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
                                option.label.indexOf(inputValue) !== -1
                        }
                        placeholder={isEnglish() ? "Search for gallery item" : "חיפוש פריט בגלריה"}

                    />
                    {isHebrew() && (
                        <Title level={5}>פריט גלריה</Title>

                    )}
                    {isEnglish() &&
                        <Title level={5}>Gallery Item</Title>

                    }
                    {selectedGalleryItem && <Title style={{ margin: 0 }} level={4}>{selectedGalleryItem.label}</Title>}
                </>
                : <Title level={5}>{isEnglish() ? "No items found" : "לא נמצאו פריטים"}</Title>}
            <Divider />
            <FileInfoWrapperBottom>
                <Button
                    key="submit"
                    type="primary"
                    style={{ marginTop: '15px' }}
                    disabled={isCreateDisabled()}
                    onClick={!linkItem ? createLinkInfo : updateLinkInfo}>
                    {!linkItem ? "Create" : "Save"}
                </Button>
                <Button
                    key="close"
                    type="primary"
                    style={{ marginTop: '15px' }}
                    onClick={closePopover}
                    danger
                >
                    Cancel
                </Button>
            </FileInfoWrapperBottom>
        </FileInfoWrapper>
    )
}
